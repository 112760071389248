import { fetchBrands, fetchCategories, fetchFastSearches, fetchProducts, fetchServices } from "./databaseFunctions";
import _ from 'lodash'
import { localStrings } from "./constants";
const { fastSearchType } = localStrings;
/**
 * Data Management Class
 * It contains updated form of all products, categories, brands, dealers etc.
 */
class DM {

    constructor() {

        console.log('Data Management constructor started')

        this.categories = null;
        this.brands = null;
        this.products = [];
        this.productsForAudit = [];
        this.dealers = null;
        this.users = null;
        this.personels = null;
        this.loggedInUser = null;
        this.blogs = null;
        this.slider = null;
        this.feedbacks = null;
        this.professionals = null;
        this.services = null;
        this.serviceFastSearches = null;
        this.productFastSearches = null;
    }

    setUsers(users) {
        this.users = users;
    }

    getUsers() {
        return this.users;
    }

    updateUsers(user) {
        var index = _.findIndex(this.users, { username: user.username });

        if (index == -1) {
            this.users.push(user)
        } else {
            this.users[index] = user;
        }

        // console.log('geldi:', this.users);
    }

    deleteUser(username) {
        this.users = _.remove(this.users, (user) => { return user.username !== username })
    }

    setPersonels(personels) {
        this.personels = personels;
    }

    getPersonels() {
        return this.personels;
    }

    deletePersonel(username) {
        this.personels = _.remove(this.personels, (personel) => { return personel.username !== username })
    }

    updatePersonels(user) {
        var index = _.findIndex(this.personels, { username: user.username });

        if (index == -1) {
            this.personels.push(user)
        } else {
            this.personels[index] = user;
        }

        // console.log('geldi:', this.personels);
    }

    setDealers(dealers) {
        this.dealers = dealers;
    }

    getDealers() {
        return this.dealers;
    }

    // getSingleDealer(dealer) {
    //     let data = this.dealers.filter(item => { return item.dealer_id === dealer.dealer_id })
    //     console.log('single?', data);
    //     return data;
    // }

    updateDealers(dealer) {
        var index = _.findIndex(this.dealers, { dealer_id: dealer.dealer_id });

        // console.log('DM.this.dealers:', this.dealers);
        // console.log('index', index);
        // console.log('updatedDealer:', dealer);

        if (index == -1) {
            this.dealers.push(dealer)
        } else {
            this.dealers[index] = dealer;
        }

        // console.log('iS.update.dealer:::', this.dealers[index]);
    }

    setBrands(brands) {
        this.brands = brands;
    }

    getBrands() {
        return this.brands;
    }

    updateBrands(brand) {
        var index = _.findIndex(this.brands, { brand_id: brand.brand_id });

        if (index == -1) {
            this.brands.push(brand)
        } else {
            this.brands[index] = brand;
        }
    }

    setCategories(categories) {
        this.categories = categories;
    }

    getCategories() {
        return this.categories;
    }

    // set single product with its updated version
    // it is used especially when a product is edited in productEdit page
    // https://stackoverflow.com/questions/27641731/is-there-a-function-in-lodash-to-replace-matched-item
    // https://stackoverflow.com/questions/7364150/find-object-by-id-in-an-array-of-javascript-objects
    setProduct(product) {

        // Find item index using _.findIndex (loadsh)
        var index = _.findIndex(this.products, { materia_id: product.materia_id });
        // alternatively using vanilla js
        // index = this.products.findIndex(it=> it.materia_id === product.materia_id);

        // console.log('item.index:', index)
        // Replace item at index using native splice
        if (index == -1) {
            this.products.push(product)
        } else {
            this.products[index] = product;
        }
    }

    updateProductForAudit(product) {
        let index_1 = _.findIndex(this.productsForAudit, { materia_id: product.materia_id });
        if (index_1 !== -1) {
            this.productsForAudit[index_1] = product;
        }

        let index_2 = _.findIndex(this.products, { materia_id: product.materia_id })
        if (index_2 !== -1) {
            this.products[index_2] = product;
        }


    }

    deleteProduct(materia_id) {
        this.products = _.remove(this.products, (product) => { return product.materia_id !== materia_id })
    }

    deleteBrand(brand_id) {
        this.brands = _.remove(this.brands, (brand) => { return brand.brand_id !== brand_id })
    }

    deleteDealer(dealer_id) {
        this.dealers = _.remove(this.dealers, (dealer) => { return dealer.dealer_id !== dealer_id })
    }

    deleteCategory(category_id) {
        this.categories = _.remove(this.categories, (category) => { return category.category_id !== category_id })
    }

    updateCategories(category) {
        var index = _.findIndex(this.categories, { category_id: category.category_id });

        if (index == -1) {
            this.categories.push(category)
        } else {
            this.categories[index] = category;
        }
    }

    setProducts(products) {
        this.products = products;
    }

    getProducts() {
        return this.products;
    }

    setProductsForAudit(products) {
        this.productsForAudit = products;
    }

    getProductsForAudit() {
        return this.productsForAudit;
    }


    /**
     * Given category id as string returns Category object
     * @param {String} id Category id such as "L-1.2.2.1"
     * @returns {Object.<Category>} Category object
     */
    findCategory = (id) => {
        if (this.categories == null) {
            fetchCategories().then(res => {
                return res.find(item => item.category_id == id);
            })
        } else if (id == '') {
            return this.categories.find(item => item.category_id == 'P')
        } else {
            return this.categories.find(item => item.category_id == id);
        }
    }

    /**
     * Given category id as string returns Brand object
     * @param {String} id Brand id such as "BR-000001"
     * @returns {Object.<Category>} Brand object
     */
    findBrand = (id) => {
        if (!id)
            return { brand_name: '' }

        if (!this.brands) {
            fetchBrands().then(res => {
                return res.find(item => item.brand_id.localeCompare(id) == 0);
            })
        } else {
            return this.brands.find(item => item.brand_id.localeCompare(id) == 0);
        }

    }

    findProduct = (id) => {

        if (!this.products) {
            fetchProducts();
            return { product_id: '' };
        }

        return this.products.find(item => item.materia_id == id);
    }

    setLoggedInUser = (user) => {
        this.loggedInUser = user;
    }

    getLoggedInUser = () => {
        if (!this.loggedInUser) {
            let data = {
                username: localStorage.getItem("username"),
                name: localStorage.getItem("name"),
                role: localStorage.getItem("role"),
                access_token: localStorage.getItem("access_token")
            }
            this.setLoggedInUser(data)
            return data
        }
        return this.loggedInUser;
    }

    setBlogs(blogs) {
        this.blogs = blogs;
    }

    getBlogs() {
        return this.blogs;
    }

    setSlider(slider) {
        this.slider = slider;
    }

    getSlider() {
        return this.slider;
    }

    setFeedbacks(feedbacks) {
        this.feedbacks = feedbacks;
    }

    getFeedbacks() {
        return this.feedbacks;
    }

    updateFeedbacks(feedback) {
        var index = _.findIndex(this.feedbacks, { _id: feedback._id });

        if (index !== -1) {
            this.feedbacks[index] = feedback;
        }
    }

    deleteFeedback(id) {
        this.feedbacks = _.remove(this.feedbacks, (feedback) => { return feedback._id !== id })
    }

    setProfessionals(professionals) {
        this.professionals = professionals;
    }

    getProfessionals() {
        return this.professionals;
    }

    updateProfessionals(professional) {
        var index = _.findIndex(this.professionals, { _id: professional._id });

        if (index !== -1) {
            this.professionals[index] = professional;
        }
    }

    // deleteFeedback(id) {
    //     this.professionals = _.remove(this.professionals, (professional) => { return professional._id !== id })
    // }


    setServices(services) {
        this.services = services;
    }

    getServices() {
        return this.services;
    }

    findService = (id) => {
        if (this.services == null) {
            fetchServices().then(res => {
                return res.find(item => item.service_id == id);
            })
        } else if (id == '') {
            return this.services.find(item => item.service_id == 'P')
        } else {
            return this.services.find(item => item.service_id == id);
        }
    }

    updateService(service) {
        var index = _.findIndex(this.services, { _id: service._id });

        if (index === -1) {
            this.services.push(service)
        } else {
            this.services[index] = service;
        }
    }

    deleteService(id) {
        this.services = _.remove(this.services, (service) => { return service._id !== id })
    }


    setServiceFastSearches(fastSearches) {
        this.serviceFastSearches = fastSearches;
    }

    getServiceFastSearches() {
        return this.serviceFastSearches;
    }

    findServiceFastSearch = (uid) => {
        if (this.serviceFastSearches === null) {
            fetchFastSearches(fastSearchType.service).then(res => {
                return res.find(item => item._uid === uid);
            })
        } else {
            return this.serviceFastSearches.find(item => item._uid === uid);
        }
    }

    findServiceFastSearch(fastSearch) {
        var index = _.findIndex(this.serviceFastSearches, { _uid: fastSearch._uid });

        if (index === -1) {
            this.serviceFastSearches.push(fastSearch)
        } else {
            this.serviceFastSearches[index] = fastSearch;
        }
    }

    deleteServiceFastSearch(uid) {
        this.serviceFastSearches = _.remove(this.serviceFastSearches, (fastSearch) => { return fastSearch._uid !== uid })
    }

    setProductFastSearches(fastSearches) {
        this.productFastSearches = fastSearches;
    }

    getProductFastSearches() {
        return this.productFastSearches;
    }

    findProductFastSearch = (uid) => {
        if (this.productFastSearches === null) {
            fetchFastSearches(fastSearchType.product).then(res => {
                return res.find(item => item._uid === uid);
            })
        } else {
            return this.productFastSearches.find(item => item._uid === uid);
        }
    }

    findProductFastSearch(fastSearch) {
        var index = _.findIndex(this.productFastSearches, { _uid: fastSearch._uid });

        if (index === -1) {
            this.productFastSearches.push(fastSearch)
        } else {
            this.productFastSearches[index] = fastSearch;
        }
    }

    deleteProductFastSearch(uid) {
        this.productFastSearches = _.remove(this.productFastSearches, (fastSearch) => { return fastSearch._uid !== uid })
    }

}


export default (new DM);